<template>
  <div class="page4-row-box">
    <div class="page4-row">
        <div class="base-data-card">
        <el-row>
          <el-row>
            <div class="memberCardPage">
              <div class="memberCardBox">
                <div class="cardId">NO.{{ userInfo.userCode }}</div>
              </div>
              <div class="reg_row_right">
                <img :src="userInfo.zhaoPian" alt=""/>
              </div>

            </div>
          </el-row>
          <el-form :model="userInfo" ref="userInfo" label-width="180px">
            <el-row :span="20" class="reg_row_col">
              <el-col :span="12">
                <el-form-item label="用户编号：">
                  <span>{{this.userInfo.userCode}}</span>
                </el-form-item>
                <el-form-item label="用户名：">
                  <span>{{this.userInfo.username}}</span><span style="color: #409EFF; font-weight: bold;margin-left:8px">（{{['免费用户','收费用户'][this.userInfo.isPay]}}）</span>
                </el-form-item>
                <el-form-item label="性别：">
                  <span>{{['','男','女'][this.userInfo.sex]}}</span>
                </el-form-item>
                <el-form-item label="证件类型：">
                  <span>{{this.userInfo.zhengJianLeiXing}}</span>
                </el-form-item>
                 <el-form-item label="政治面貌：">
                  <span>{{this.userInfo.zhengZhiMianMao}}</span>
                </el-form-item>
                 <el-form-item label="移动电话：">
                  <span>{{this.userInfo.mobile}}</span>
                </el-form-item>
                <el-form-item label="总会：">
                  <span>{{this.userInfo.zongHui}}</span>
                </el-form-item>
                <el-form-item label="职务：">
                  <span>{{this.userInfo.zhiWu}}</span>
                </el-form-item>
                
                <el-form-item label="单位名称：">
                  <span>{{this.userInfo.danWeiMingCheng}}</span>
                </el-form-item>
                 <el-form-item label="邮编：">
                  <span>{{this.userInfo.youBian}}</span>
                </el-form-item>
                 <el-form-item label="专业：">
                  <span>{{this.userInfo.zhuanYe}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12" :pull="2">
                <el-form-item label="会员类型：">
                  <span>{{this.userInfo.type}}</span>
                </el-form-item>
                <el-form-item label="姓名：">
                  <span>{{this.userInfo.name}}</span>
                </el-form-item>
                <el-form-item label="出生年月：">
                  <span>{{this.userInfo.chuShengNianYue}}</span>
                </el-form-item>
                 <el-form-item label="证件号码：">
                  <span>{{this.userInfo.zhengJianHaoMa}}</span>
                </el-form-item>
                <el-form-item label="民族：">
                  <span>{{this.userInfo.minZu}}</span>
                </el-form-item>
                <el-form-item label="邮箱：">
                    <span>{{this.userInfo.email}}</span>
                </el-form-item>
                <el-form-item label="分会：">
                  <span>{{(this.userInfo.checkList && this.userInfo.checkList.length)?this.userInfo.checkList.join(',') : ''}}</span>
                </el-form-item>
                <el-form-item label="职称：">
                  <span>{{this.userInfo.zhiCheng}}</span>
                </el-form-item>
                <el-form-item label="科室：">
                  <span>{{this.userInfo.keShi}}</span>
                </el-form-item>
                
              </el-col>
              <el-col :span="24">
                  
                  <el-form-item label="单位地址：">
                      <span>{{this.userInfo.danWeiDiZhi}}</span>
                  </el-form-item>
                  <el-form-item label="主要学习经历：">
                      <span>{{this.userInfo.studyLog}}</span>
                  </el-form-item>
                  <el-form-item label="主要学术或科普论著、研究成果：">
                      <span>{{this.userInfo.chengGuo}}</span>
                  </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-row>
      </div>
      <!-- <div class="text">
          我们的审核时效为5个工作日，审核状态将通过您提供的手机号以短信形式告知，也可登录账号查看审核进度，如有疑问，请联系学会办公室
      </div>
      <div class="text">021-00000000</div>
      <div class="text">邮箱：secretariat_ssa@163.com</div>
      <div class="text-blue margin-top-30" > 欢迎关注我们的微信公众号、了解行业资讯，请扫描学会企业微信，以便及时收到学会所发通知</div>
      <div class="qr-code">
          <img src="../../../assets/images/shshtroke-qr.png" />
      </div> -->
      <div class="bottom-button">
        <el-button
            v-if="this.userInfo.isPay == 0"
            style="width:120px"
            type="primary"
            @click="toPay"
        >去支付</el-button>
      </div>
    </div>
  </div>
</template>

<script>

import {getUserInfo} from "../../http/request";
export default {
    props: {},
    data() {
        return {
            userInfo:{},// 用户信息
            modleFlag:false,// 弹窗
        }
    },
    methods: {
        // 查询个人信息
        getUserInfo() {
            getUserInfo().then(({data})=>{
                this.userInfo = data.data
            })
        },
        // 获取支付状态
        getPayStatus() {

        },
        // 去支付页面
        toPay() {
            window.open(`${window.location.origin}/#/payDeal/${this.userInfo.id}`,'_blank');
            this.$confirm('确认已支付?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              getUserInfo().then(({data})=>{
                 this.userInfo = data.data
                  if(data.data.isPay === 0){
                    this.$message({
                      type: 'error',
                      message: '支付未成功!'
                    });
                  }else{
                    this.$message({
                      type: 'success',
                      message: '支付成功!'
                    });
                  }
              })
            }).catch(() => {
              this.getUserInfo()
              this.$message({
                type: 'error',
                message: '已取消支付'
              });          
            });
        },
    },
    mounted() {
      this.getUserInfo(),
      this.getPayStatus()
    },
}
</script>

<style lang="scss">
.page4-row-box{
  padding-top: 30px;
  .page4-row{
      width: 1100px;
      margin: 0 auto;
      border: 2px solid #409EFF;
      border-radius: 8px;
      padding:20px 10px;
      .head-icon-row{
          display: flex;
          justify-content: center;
          align-items: center;
          font-size:22px;
          font-weight: 500;
          color: #3FB743;
          img{
              width: 60px;
              height: 60px;
              margin-right: 20px;
          }
      }
      .text{
          text-align: center;
          font-size: 16px;
          color: #333333;
          line-height: 30px;
          margin-top: 10px;
          font-weight: 500;
      }
      .text-blue{
          text-align: center;
          font-size: 16px;
          color: #014DA3;
          line-height: 30px;  
      }
      .margin-top-30{
          margin-top: 30px;
      }
      .qr-code{
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 30px 0;
          img{
              width: 90px;
              height: 91px;
          }
      }
  }
  .reg_row_right{
    width:120px;
    height:170px;
    border: 2px solid #409EFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .el-form-item {
    margin-bottom: 0px
  }
}
.bottom-button{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 60px;
  box-sizing: border-box;
}
.memberCardPage {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 15px 50px;
  box-sizing: border-box;
}
.memberCardBox {
  width: 378px;
  height: 248px;
  border-radius: 10px;
  background-image: url('../../assets/images/menberCard.png');
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.cardId {
  width: 150px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  background-color: #123c6d;
  border-bottom-left-radius: 10px;
  padding-left: 22px;
  box-sizing: border-box;
}
</style>